define("discourse/plugins/discourse-user-feedback/discourse/controllers/user-feedback", ["exports", "discourse-common/utils/decorators", "@ember/controller"], function (_exports, _decorators, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    queryParams: ["scoped", "posted_by"],
    scoped: null,
    posted_by: null,
    canCreateFeedback(currentUserId, userId) {
      return currentUserId && currentUserId !== userId;
    },
    actions: {
      toggleFilter(filter, val) {
        const newVal = this.get(filter) === val ? null : val;
        const newProps = {
          scoped: null
        };
        newProps[filter] = newVal;
        this.setProperties(newProps);
      }
    }
  }, [["method", "canCreateFeedback", [(0, _decorators.default)("currentUser.id", "model.user.id")]]]));
});