define("discourse/plugins/discourse-user-feedback/discourse/components/feedback-stream-item", ["exports", "discourse/components/user-stream-item", "discourse-common/utils/decorators"], function (_exports, _userStreamItem, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _userStreamItem.default.extend(dt7948.p({
    classNameBindings: ["item.scoped:scoped"],
    canDispute(disputed, currentUserId, userId) {
      return !disputed && currentUserId && currentUserId === userId;
    },
    canClearDispute(disputed, isStaff) {
      return disputed && isStaff;
    }
  }, [["method", "canDispute", [(0, _decorators.default)("item.disputed", "currentUser.id", "user.id")]], ["method", "canClearDispute", [(0, _decorators.default)("item.disputed", "currentUser.staff")]]]));
});