define("discourse/plugins/discourse-user-feedback/discourse/components/rating-stars", ["exports", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    starsNumber: 5,
    readOnly: true,
    rating: 0,
    didReceiveAttrs() {
      this._super(...arguments);
      const stars = [];
      for (let i = 0; i < this.get("starsNumber"); i++) {
        let star = {
          full: i < this.get("rating")
        };
        stars.push(star);
      }
      this.set("stars", stars);
    },
    didInsertElement() {
      this._super(...arguments);
      if (this.get("readOnly") === false) {
        $(this.element).find(".rating-stars").css("cursor", "pointer");
      }
    },
    click(event) {
      if (this.get("readOnly")) {
        return;
      }
      let rating = this.getTarget(event.pageX);
      if (rating <= this.get("starsNumber")) {
        this.set("rating", rating);
        if (this.get("action") !== null) {
          this.get("action")(rating);
        }
      }
    },
    updateStars: function () {
      let rating = this.get("rating");
      let stars = $(this.element).find(".star-icon");
      stars.each(function (index, element) {
        if (index < rating) {
          $(element).addClass("full");
        } else {
          $(element).removeClass("full");
        }
      });
    },
    getTarget(x) {
      const starsNumber = this.get("starsNumber");
      return Math.ceil(starsNumber * (x - $(this.element).find(".rating-stars").offset().left) / $(this.element).find(".rating-stars").width());
    }
  }, [["field", "updateStars", [(0, _decorators.observes)("rating")]]]));
});